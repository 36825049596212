import React from 'react';
import { CardImage } from '@components/core';

interface PlayerImageProps {
    image: {
        childImageSharp: {
            fluid: {
                aspectRatio: number;
                base64: string;
                sizes: string;
                src: string;
                srcSet: string;
            };
        };
    };
}

export const PlayerImage: React.FC<PlayerImageProps> = ({ image }) => {
    return <CardImage image={image} />;
};
