// Modules
import React from 'react';
import styled from '@emotion/styled';

// Components
import { Icon, IconId } from '@components/core/icon';
import { Typo } from '@components/core';

// Styles
import { spacing, colors, borderRadius } from '@styles/theme';

interface PlayerActionProps {
    onClick: () => void;
    id: IconId;
    label: string;
    disabled?: boolean;
    active?: boolean;
}

const Container = styled.button<PlayerActionProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: 'center';
    padding: ${spacing(2)};

    color: ${colors('gray', 400)};

    color: ${props => {
        if (props.id === 'play' && props.active) {
            return colors('gray', 800);
        }

        if (props.id === 'heart' && props.active) {
            return colors('red', 500);
        }

        return colors('gray', 400);
    }};

    background: none;
    border: 0;
    border-radius: ${borderRadius('md')};

    cursor: pointer;

    & > * {
        cursor: pointer;
    }

    transition: all ease 0.2s;

    :hover {
        background-color: ${props => {
            if (props.disabled) {
                return colors('transparent');
            }

            return colors('gray', 100);
        }};

        cursor: ${props => props.disabled && 'not-allowed'};
    }

    > * + label {
        margin-top: ${spacing(3)};
    }
`;

export const PlayerAction: React.FC<PlayerActionProps> = ({ id, label, ...props }) => {
    return (
        <Container id={id} label={label} {...props}>
            <Icon id={id} />
            <Typo variant="label">{label}</Typo>
        </Container>
    );
};
