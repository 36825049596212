import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { spacing, fontSize } from '@styles/theme';

interface ListProps {
    children: ReactNode[];
}

const Container: React.FC<ListProps> = ({ children, ...props }) => {
    return (
        <ul {...props}>
            {children.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
};

export const List = styled(Container)`
    margin: 0;
    padding: 0;

    list-style: none;

    > li + li {
        margin-top: ${spacing(3)};

        font-size: ${fontSize('md')};
    }
`;
