// Modules
import React from 'react';
import css from '@emotion/css';

// Components
import { Typo } from '@components/core/typo';
import { List } from '@components/core/list';
import { fontSize } from '@styles/theme';

interface LearnProps {
    learn: string[];
}

export const Learn: React.FC<LearnProps> = ({ learn }) => {
    return (
        <div>
            <Typo
                variant="h2"
                css={css`
                    font-size: ${fontSize('lg')};
                `}
            >
                Ce que je vais apprendre
            </Typo>
            <List>
                {learn.map((learn, index) => (
                    <Typo key={index}>{learn}</Typo>
                ))}
            </List>
        </div>
    );
};
