import React, { ReactNode } from 'react';
import { CardBody, Grid, CardContent } from '@components/core';
import css from '@emotion/css';
import { spacing } from '@styles/theme';

interface Props {
    children: ReactNode;
}

export const PlayerBody: React.FC<Props> = ({ children }) => {
    return (
        <CardContent>
            <CardBody
                css={css`
                    padding: ${spacing(3)} ${spacing(6)};
                `}
            >
                <Grid
                    sm={{ columns: 3, columnGap: 4, rowGap: 0 }}
                    md={{ columns: 3, columnGap: 4, rowGap: 0 }}
                    lg={{ columns: 3, columnGap: 4, rowGap: 0 }}
                >
                    {children}
                </Grid>
            </CardBody>
        </CardContent>
    );
};
