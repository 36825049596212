import React from 'react';
import Workshop from '@models/Workshop';
import { toast } from 'react-toastify';
import { ToastMessage } from '@components/core/toast-message';

export const isBrowser = (): boolean => typeof window !== 'undefined';

class LikeService {
    public getLikedWorkshops = (): string[] => {
        if (isBrowser() && window.localStorage.likedWorkshops) {
            const likedWorkshopsJson = localStorage.getItem('likedWorkshops');

            return likedWorkshopsJson ? JSON.parse(likedWorkshopsJson) : [];
        }
        return [];
    };

    public likeWorkshop = (workshop: Workshop): boolean => {
        let workshops = this.getLikedWorkshops();

        if (!workshops?.includes(workshop.path)) {
            window.localStorage.setItem('likedWorkshops', JSON.stringify([...workshops, workshop.path]));
            toast(<ToastMessage message="Workshop ajouté aux favoris" />);
            return true;
        } else {
            window.localStorage.setItem(
                'likedWorkshops',
                JSON.stringify(workshops.filter(workshopPath => workshopPath !== workshop.path)),
            );
            toast(<ToastMessage message="Workshop retiré des favoris" />);
            return false;
        }
    };
}

export default new LikeService();
