// Components
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { colors } from '@styles/theme';

export interface FooterProps {
    children: ReactNode;
}

const Container = styled.div`
    display: flex;

    background-color: ${colors('gray', 200)};
`;

export const Footer: React.FC<FooterProps> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};
