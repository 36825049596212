// Modules
import React from 'react';
import { css } from '@emotion/core';
import { Icon } from '../icon';
import { spacing, colors, fontSize } from '@styles/theme';
import { Typo } from '@components/core/typo';

interface ToastMessageProps {
    message: string;
}

export const ToastMessage: React.FC<ToastMessageProps> = ({ message }) => (
    <div
        css={css`
            display: flex;
            align-items: center;
        `}
    >
        <Icon
            id="check"
            css={css`
                margin-right: ${spacing(2)};

                color: ${colors('green', 400)};
                font-size: ${fontSize('lg')};
            `}
        />
        <Typo
            css={css`
                margin: 0 !important;

                color: ${colors('secondary')};
                font-size: ${fontSize('sm')};
            `}
        >
            {message}
        </Typo>
    </div>
);
