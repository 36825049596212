// Modules
import React from 'react';
import css from '@emotion/css';

// Styles
import { fontSize } from '@styles/theme';

// Components
import { Typo } from '@components/core/typo';
import { List } from '@components/core/list';

interface PrerequisitesProps {
    prerequisites: string[];
}

export const Prerequisites: React.FC<PrerequisitesProps> = ({ prerequisites }) => {
    return (
        <div>
            <Typo
                variant="h2"
                css={css`
                    font-size: ${fontSize('lg')};
                `}
            >
                Prérequis
            </Typo>
            <List>
                {prerequisites.map((prerequisites, index) => (
                    <Typo key={index}>{prerequisites}</Typo>
                ))}
            </List>
        </div>
    );
};
