// Components
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { colors, spacing, screens } from '@styles/theme';

export interface CardProps {
    children: ReactNode;
}

const Container = styled.div`
    display: flex;

    background-color: ${colors('gray', 100)};

    @media (max-width: ${screens('md')}) {
        padding: ${spacing(8)} 0;

        > div:first-of-type {
            padding: 0 ${spacing(8)};
        }
    }
`;

export const Header: React.FC<CardProps> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};
