// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';

// Components
import { Card } from '@components/core/card';
import { spacing, screens } from '@styles/theme';

export interface PlayerInterface {
    children: ReactNode;
}

const StyledCard = styled(Card)`
    @media (min-width: ${screens('lg')}) {
        width: 100%;
        max-height: ${spacing(72)};
    }

    @media (max-width: ${screens('lg')}) {
        display: none;
    }
`;

export const Player: React.FC<PlayerInterface> = ({ children, ...props }) => {
    return <StyledCard {...props}>{children}</StyledCard>;
};
