import React, { Props } from 'react';
import { Link } from '@reach/router';
import styled from '@emotion/styled';
import { spacing, colors } from '@styles/theme';
import { Icon } from '@components/core/icon';
import { Typo } from '@components/core/typo';
import css from '@emotion/css';

interface ChapterProps {
    path: string;
    length: number;
    title: string;
}

const Container = styled.li`
    a {
        display: flex;
        align-items: center;
        padding: ${spacing(6)};
    }

    svg {
        margin-right: ${spacing(8)};

        color: ${colors('gray', 400)};
    }

    :hover svg,
    :hover label {
        color: ${colors('gray', 800)};
    }
`;

export const Chapter: React.FC<ChapterProps> = ({ path, length, title }) => {
    return (
        <Container>
            <Link to={path}>
                <Icon id="play" />
                <div
                    css={css`
                        > label + h3 {
                            margin-top: ${spacing(3)};
                        }
                    `}
                >
                    <Typo
                        variant="label"
                        css={css`
                            color: ${colors('gray', 400)};
                        `}
                    >
                        {length} min de lecture
                    </Typo>
                    <Typo variant="h3">{title}</Typo>
                </div>
            </Link>
        </Container>
    );
};
