// Modules
import React from 'react';
import css from '@emotion/css';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

// Styles
import { spacing, fontFamily, screens } from '@styles/theme';

// Components
import { Typo, Grid } from '@components/core';
import { Learn } from '@components/workshop/learn';
import { Prerequisites } from '@components/workshop/prerequisites';

interface ContentProps {
    className: string;
    title: string;
    body: string;
    learn?: string[];
    prerequisites?: string[];
}

export const Content: React.FC<ContentProps> = ({ title, body, learn, prerequisites, ...props }) => {
    return (
        <div
            css={css`
                grid-area: 1 / 1 / 2 / 3;
                padding-bottom: ${spacing(8)};
            `}
            {...props}
        >
            <Typo variant="h1">{title}</Typo>
            <div
                css={css`
                    font-family: ${fontFamily('sans')};
                `}
            >
                <MDXRenderer>{body}</MDXRenderer>
            </div>
            {learn && prerequisites && (
                <Grid
                    sm={{ columns: 1, columnGap: 0, rowGap: 8 }}
                    md={{ columns: 2, columnGap: 16, rowGap: 0 }}
                    lg={{ columns: 2, columnGap: 16, rowGap: 0 }}
                >
                    <Learn learn={learn} />
                    <Prerequisites prerequisites={prerequisites} />
                </Grid>
            )}
        </div>
    );
};
