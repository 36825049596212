// Modules
import React, { useState, useEffect } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer, Slide } from 'react-toastify';

// Services
import LikeService from '@services/LikeService';

// Factories
import WorkshopFactory from '@factories/WorkshopFactory';

// Styles
import { spacing, screens, colors, fontSize, borderRadius, fontWeight, boxShadow } from '@styles/theme';
import css from '@emotion/css';
import 'react-toastify/dist/ReactToastify.css';

// Components
import { Layout } from '@components/core/layout';
import { Outer } from '@components/core/outer';
import { Inner } from '@components/core/inner';
import { Typo } from '@components/core/typo';
import { Header } from '@components/core/header';
import { Grid } from '@components/core/grid';
import { Content } from '@components/workshop/content';
import { Player } from '@components/core/player/player';
import { Chapter } from '@components/workshop/chapter';
import { Card } from '@components/core/card';
import { Footer } from '@components/core/footer/Footer';
import { PlayerImage } from '@components/core/player/player-image';
import { PlayerBody } from '@components/core/player/player-body';
import { PlayerAction } from '@components/core/player/player-action/PlayerAction';
import { Metadata } from '@components/core/metadata';
import { WindowLocation } from '@reach/router';
import { Icon } from '@components/core/icon';
import { ToastMessage } from '@components/core/toast-message';

interface WorkshopTemplateProps {
    data: any;
    location: WindowLocation;
}

const WorkshopTemplate: React.FC<WorkshopTemplateProps> = ({ data, location, ...props }) => {
    const node = data.mdx;
    const workshop = WorkshopFactory.create(node);
    const chapters = WorkshopFactory.batchCreate(data.allMdx.edges);
    const { title, image, body, learn, prerequisites } = workshop;
    const { path } = chapters[0]; // 💡 Resume feature : refactor to make dynamic when AutoSave will be implemented

    if (typeof window === 'undefined') {
        return null;
    }

    const [liked, setLike] = useState(false);

    useEffect(() => {
        const isLiked = LikeService.getLikedWorkshops().includes(workshop.path);

        setLike(isLiked);
    }, []);

    const handleLike = (): void => {
        LikeService.likeWorkshop(workshop);
        const isLiked = LikeService.getLikedWorkshops().includes(workshop.path);
        setLike(isLiked);
    };

    return (
        <Layout>
            <Metadata pathname={location.pathname} ressource={workshop} />
            <ToastContainer
                css={css`
                    > div {
                        padding: ${spacing(3)};

                        border-left: ${spacing(1)} solid ${colors('green', 400)};
                        border-radius: ${borderRadius('md')};
                        box-shadow: ${boxShadow('md')};
                    }
                `}
                position="bottom-right"
                transition={Slide}
                hideProgressBar={true}
                closeButton={false}
                autoClose={2500}
            />
            <Outer>
                <Header>
                    <Inner>
                        <Link
                            to="/"
                            css={css`
                                :hover label {
                                    color: ${colors('secondary')};
                                }

                                svg {
                                    transform: translate3d(0, 0, 0);

                                    transition: all 0.133s ease-out;
                                }

                                :hover svg {
                                    color: ${colors('secondary')};

                                    transform: translate3d(-20px, 0, 0);
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: inline-flex;
                                    align-items: center;
                                `}
                            >
                                <Icon
                                    id="arrow-left"
                                    css={css`
                                        margin-right: ${spacing(6)};

                                        color: ${colors('gray', 600)};
                                    `}
                                />
                                <div>
                                    <Typo
                                        variant="label"
                                        css={css`
                                            color: ${colors('gray', 600)};
                                            cursor: pointer;

                                            transition: all 0.1333s ease-out;
                                        `}
                                    >
                                        Retour aux
                                    </Typo>
                                    <Typo
                                        variant="h2"
                                        css={css`
                                            margin-top: ${spacing(1)};

                                            color: ${colors('secondary')};
                                        `}
                                    >
                                        Expéditions
                                    </Typo>
                                </div>
                            </div>
                        </Link>
                    </Inner>
                </Header>
            </Outer>
            <Inner
                css={css`
                    margin-bottom: ${spacing(8)};

                    @media (min-width: ${screens('md')}) {
                        margin-bottom: ${spacing(32)};
                    }
                `}
            >
                <Grid
                    sm={{ columns: 1, columnGap: 0, rowGap: 0 }}
                    md={{ columns: 1, columnGap: 0, rowGap: 0 }}
                    lg={{ columns: 3, columnGap: 16, rowGap: 0 }}
                >
                    <Content
                        className="mdx-content"
                        title={title}
                        body={body}
                        learn={learn}
                        prerequisites={prerequisites}
                    />
                    <div
                        css={css`
                            position: absolute;
                            top: -${spacing(32)};

                            grid-area: 1 / 3 / 2 / 4;

                            @media (max-width: ${screens('lg')}) {
                                display: none;
                            }
                        `}
                    >
                        <Player>
                            <PlayerImage image={image} />
                            {/*                <div
                                css={css`
                                    position: relative;
                                    width: 100%;
                                    height: ${spacing(2)};
                                    background-color: ${colors('gray', 400)};
                                `}
                            >
                                <div
                                    css={css`
                                        position: absolute;
                                        width: 60%;
                                        height: 100%;
                                        background-color: ${colors('green', 400)};
                                    `}
                                />
                            </div> */}
                            <PlayerBody>
                                <PlayerAction
                                    onClick={() => {
                                        handleLike();
                                    }}
                                    id="heart"
                                    label="Favoris"
                                    active={liked}
                                />
                                <PlayerAction
                                    onClick={() => {
                                        console.log('Start ⏯');
                                        navigate(path);
                                    }}
                                    id="play"
                                    label="Commencer"
                                    active={true}
                                />
                                <PlayerAction
                                    onClick={() => {
                                        console.log('Skip ⏩');
                                    }}
                                    id="step-forward"
                                    label="Suivant"
                                    disabled
                                />
                            </PlayerBody>
                        </Player>
                        <Card
                            css={css`
                                margin-top: ${spacing(6)};
                                padding: ${spacing(6)};
                            `}
                        >
                            <a
                                href="https://github.com/PaulCailly/atelier-websockets-whatsapp-clone"
                                target="_blank"
                                rel="noopener noreferrer"
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    padding: ${spacing(3)} 0;
                                    padding-top: 0;
                                `}
                            >
                                <Icon
                                    id="github"
                                    css={css`
                                        margin-right: ${spacing(3)};
                                    `}
                                />
                                <Typo
                                    variant="h3"
                                    css={css`
                                        font-weight: ${fontWeight('normal')};
                                        font-size: ${fontSize('sm')};
                                    `}
                                >
                                    Code source
                                </Typo>
                            </a>
                            <CopyToClipboard
                                text={
                                    'git clone --branch 01/introduction https://github.com/PaulCailly/atelier-websockets-whatsapp-clone.git'
                                }
                                onCopy={() => {
                                    toast(<ToastMessage message="Commande copiée dans le clipboard" />);
                                }}
                            >
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        padding: ${spacing(3)} 0;
                                        padding-bottom: 0;

                                        :hover {
                                            cursor: pointer;
                                        }
                                    `}
                                >
                                    <Icon
                                        id="git"
                                        css={css`
                                            margin-right: ${spacing(3)};
                                        `}
                                    />

                                    <Typo
                                        variant="h3"
                                        css={css`
                                            width: 100%;

                                            font-size: ${fontSize('sm')};
                                        `}
                                    >
                                        <input
                                            readOnly
                                            css={css`
                                                width: 100%;
                                                padding: ${spacing(1)} ${spacing(2)};

                                                text-overflow: ellipsis;

                                                border: 1px solid ${colors('gray', 300)};
                                                border-radius: ${borderRadius('md')};
                                            `}
                                            value="git clone --branch 01/introduction https://github.com/PaulCailly/atelier-websockets-whatsapp-clone.git"
                                        />
                                    </Typo>
                                </div>
                            </CopyToClipboard>
                        </Card>
                    </div>
                </Grid>
            </Inner>
            <Outer>
                <Footer>
                    <Inner>
                        <Card
                            css={css`
                                position: relative;
                                top: -${spacing(32)};

                                @media (max-width: ${screens('md')}) {
                                    top: -${spacing(16)};
                                }
                            `}
                        >
                            <ul
                                css={css`
                                    margin: 0;
                                    padding: ${spacing(6)};

                                    list-style: none;

                                    li + li {
                                        border-top: 1px solid ${colors('gray', 100)};
                                    }
                                `}
                            >
                                {chapters.map(chapter => {
                                    const { id, path, length, title } = chapter;
                                    return <Chapter key={id} path={path} length={length} title={title} />;
                                })}
                            </ul>
                        </Card>
                    </Inner>
                </Footer>
            </Outer>
        </Layout>
    );
};

export default WorkshopTemplate;

export const WorkshopTemplateQuery = graphql`
    query AllChaptersAndCurrentWorkshop($id: String!) {
        allMdx(
            sort: { fields: frontmatter___index, order: ASC }
            filter: { fields: { contentType: { eq: "chapter" } } }
        ) {
            edges {
                node {
                    fields {
                        id
                        path
                    }
                    frontmatter {
                        index
                        title
                        description
                        date
                        length
                    }
                }
            }
        }
        mdx(fields: { id: { eq: $id } }) {
            fields {
                id
                path
            }
            body
            frontmatter {
                index
                image {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                title
                description
                date
                tags
                learn
                prerequisites
            }
        }
    }
`;
